import { createTheme } from "@mui/material/styles";

export const kozienTheme = createTheme({
    palette: {
        primary: {
            main: "#91DD32",
            contrastText: "#27353A",
            light: "#fff",
            dark: "#78B928",
        },
        secondary: { main: "#F67913", contrastText: "#ffffff" },
        error: { main: "#f44336", dark: "#a52a21" },
        warning: { main: "#efad41" },
        success: { main: "#78B928" },
        neutral: { main: "#333", contrastText: "#F9F9F9", light: "#E6E6E6" },
        anthracite: "#27353A",
        background: {
            default: "#fff",
            paper: "#F4FAFC",
        },
        border: "#E1E5E6",
    },
});
