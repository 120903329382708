import { Logo } from "@/website/atoms/Logo/Logo";
import { MainMenu } from "@/website/molecules/MainMenu/MainMenu";
import {
    AppBar,
    Button,
    Container,
    IconButton,
    Link,
    Stack,
} from "@mui/material";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle, useWindowSize } from "usehooks-ts";
import { StyledDrawer } from "./Header.styles";
import { theme } from "@/styles/theme";
import { Close, Menu } from "@mui/icons-material";
import { SiteContext } from "@/contexts/Site/Site.context";

export const Header = () => {
    const navigate = useNavigate();
    const { width = 0 } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const isMobile = width < theme.breakpoints.values.md;
    const [mobileMenu, toggleMobileMenu] = useToggle();
    const siteContext = useContext(SiteContext);

    const linkList =
        siteContext.categories
            .map((category) => {
                const { name, slug, id } = category;
                if (!id) return null;
                return {
                    label: name,
                    url: `/products/${slug}`,
                    id: id,
                };
            })
            .filter((item) => item !== null) ?? [];

    const onOfferClick = () => {
        navigate("/quote");
    };

    const menu = <MainMenu list={linkList} />;

    return (
        <>
            <AppBar
                position="sticky"
                sx={{
                    zIndex: "1300", // not an magic, Mui drawer has 1200 :-)
                    backgroundColor: theme.palette.background.default,
                }}
                variant="outlined"
                ref={ref}
                elevation={0}
            >
                <Container>
                    <Stack
                        direction="row"
                        spacing={2}
                        py={2.5}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Stack
                            direction={"row"}
                            spacing={5}
                            alignItems={"center"}
                        >
                            <Link p={0} height={20} width={"auto"} href="/">
                                <Logo />
                            </Link>
                            {!isMobile ? menu : null}
                        </Stack>

                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onOfferClick}
                            >
                                Offerte
                            </Button>
                            {isMobile ? (
                                <IconButton onClick={toggleMobileMenu}>
                                    {mobileMenu ? <Close /> : <Menu />}
                                </IconButton>
                            ) : null}
                        </Stack>
                    </Stack>
                </Container>
            </AppBar>

            {isMobile ? (
                <StyledDrawer
                    open={mobileMenu}
                    menuheight={ref.current?.clientHeight || 0}
                    hideBackdrop
                >
                    {menu}
                </StyledDrawer>
            ) : null}
        </>
    );
};
