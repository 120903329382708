import { SiteContext } from "@/contexts/Site/Site.context";
import { useContext } from "react";
import LogoKozien from "src/logos/logo-kozien.svg";
import LogoThermototaal from "src/logos/logo-thermototaal.svg";

export const Logo = () => {
    const siteContext = useContext(SiteContext);
    let logoSrc = "";

    if (siteContext.siteLabel === "kozien") {
        logoSrc = LogoKozien;
    }

    if (siteContext.siteLabel === "thermototaal") {
        logoSrc = LogoThermototaal;
    }

    return (
        <img
            src={logoSrc}
            alt={siteContext.siteLabel}
            style={{ height: "100%", width: "auto" }}
        />
    );
};
