import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import nlCommon from "./locales/nl/common.json";
import nlTranslation from "./locales/nl/translation.json";

const nl = { common: nlCommon, translation: nlTranslation };

import devCommon from "./locales/dev/common.json";
import devTranslation from "./locales/dev/translation.json";

const dev = { common: devCommon, translation: devTranslation };

i18n.use(initReactI18next).init({
    resources: { nl, dev },
    debug: false,
    lng: "nl",
    defaultNS: "common",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
