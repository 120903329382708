interface Logger {
    verbose: (...args: unknown[]) => void;
    debug: (...args: unknown[]) => void;
    info: (...args: unknown[]) => void;
    warn: (...args: unknown[]) => void;
    error: (...args: unknown[]) => void;
    trace: (...args: unknown[]) => void;
    log: (...args: unknown[]) => void;
}

export function createLogger(
    name?: string,
    console = globalThis.console,
): Logger {
    console = console || createNoopConsole();

    return {
        verbose: console.debug.bind(console, `[VERBOSE]`, `[${name}]`),
        debug: console.debug.bind(console, `[DEBUG]`, `[${name}]`),
        info: console.info.bind(console, `[INFO]`, `[${name}]`),
        warn: console.warn.bind(console, `[WARN]`, `[${name}]`),
        error: console.error.bind(console, `[ERROR]`, `[${name}]`),
        trace: console.trace.bind(console, `[TRACE]`, `[${name}]`),
        log: console.log.bind(console, `[LOG    ]`, `[${name}]`),
    };
}

function createNoopConsole(): Logger {
    return {
        verbose: () => {},
        debug: () => {},
        error: () => {},
        info: () => {},
        log: () => {},
        trace: () => {},
        warn: () => {},
    };
}

export const logger = createLogger();

export default logger;
