import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import possibleTypes from "@graphql/cms/possibleTypes";

const client = new ApolloClient({
    link: new HttpLink({
        uri: import.meta.env.VITE_CMS_GRAPHQL_ENDPOINT,
    }),
    cache: new InMemoryCache({
        possibleTypes: possibleTypes.possibleTypes,
    }),
    connectToDevTools: Boolean(import.meta.env.VITE_APOLLO_DEVTOOLS),
});

export default client;
