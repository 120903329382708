import { RouterProvider } from "react-router-dom";
import { createRouter } from "@/router/index";
import { useContext } from "react";
import { SiteContext } from "@/contexts/Site/Site.context";

export const CustomRouterProvider = () => {
    const categories =
        useContext(SiteContext).categories.map((category) => {
            return {
                path: `/products/${category?.slug}`,
                lazy: () => import("@/pages/Products"),
            };
        }) ?? [];

    return <RouterProvider router={createRouter(categories)} />;
};
