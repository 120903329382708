import { type PageTemplateProps } from "./SimplePage.types";
import Box from "@mui/material/Box";
import RedirectBar from "@/website/organisms/RedirectBar";
import { Header } from "@/website/organisms/Header/Header";

export function SimplePageTemplate({ mainContent }: PageTemplateProps) {
    return (
        <>
            <RedirectBar />
            <Header />
            <Box component="main">{mainContent}</Box>
        </>
    );
}
