import { ArrowForwardIos } from "@mui/icons-material";
import { Link, Stack } from "@mui/material";
import type { MainMenuListItem } from "./MainMenu.types";
import { theme } from "@/styles/theme";

interface MainMenuProps {
    list: MainMenuListItem[];
}

export const MainMenu = (props: MainMenuProps) => {
    const { list } = props;
    return (
        <nav style={{ width: "100%" }}>
            <Stack
                direction={{ xs: "column", md: "row" }}
                gap={{ xs: 0, md: 3 }}
                width={{ xs: "100%", md: "auto" }}
            >
                {list.map((item) => (
                    <Link
                        key={item.id}
                        href={item.url}
                        fontSize={{ xs: "1.5rem", md: "1rem" }}
                        py={{ xs: 1.375, md: 0 }}
                        fontWeight={500}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        color={theme.palette.anthracite}
                        sx={{ textDecoration: "none" }}
                    >
                        {item.label}
                        <ArrowForwardIos
                            sx={{
                                fontSize: 12,
                                display: { xs: "block", md: "none" },
                            }}
                        />
                    </Link>
                ))}
            </Stack>
        </nav>
    );
};
