import type { SiteLabel } from "@/types/SiteLabel";
import { SiteLabels } from "@/types/SiteLabel";
import { createContext, type ReactNode } from "react";
import { type CmsCategory, useGetCategoriesQuery } from "@graphql/cms/graphql";

const siteLabel = import.meta.env.VITE_PUBLIC_LABEL as SiteLabel;

if (
    !siteLabel ||
    ![SiteLabels.kozien, SiteLabels.thermoTotaal].includes(siteLabel)
) {
    throw new Error("Invalid or missing site label in context");
}

const SiteContext = createContext<{
    siteLabel: SiteLabel;
    categories: CmsCategory[];
}>({ siteLabel, categories: [] });

function SiteProvider({ children }: { children: ReactNode }) {
    const { data } = useGetCategoriesQuery();
    const categories =
        data?.Categories?.docs?.filter(
            (x: CmsCategory | null): x is CmsCategory => x !== null,
        ) ?? [];

    return (
        <SiteContext.Provider value={{ siteLabel, categories: categories }}>
            {children}
        </SiteContext.Provider>
    );
}

export { SiteContext, SiteProvider };
