import type { NumericFeature } from "./NumericFeature";

export function isNumericFeature(feature: unknown): feature is NumericFeature {
    return (
        typeof feature === "object" &&
        feature !== null &&
        "key" in feature &&
        "type" in feature &&
        feature.type === "NumericFeature"
    );
}
