import css from "./RedirectBar.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRequestQuoteStore } from "@/store/requestQuote";
import { useTranslation } from "react-i18next";
import InformationBar from "@/website/molecules/InformationBar";

export function RedirectBar() {
    const { t } = useTranslation();
    const dealId = useRequestQuoteStore((state) => state.dealId);
    const firstName = useRequestQuoteStore((state) => state.givenName);
    const lastName = useRequestQuoteStore((state) => state.familyName);
    const reset = useRequestQuoteStore((state) => state.reset);

    const handleClick = () => {
        reset();
    };

    if (dealId === "") {
        return null;
    }

    return (
        <InformationBar>
            <strong>{t("hubspotUser", "Hubspot gebruiker")}</strong>:{" "}
            {firstName} {lastName}{" "}
            <DeleteIcon
                data-testid="delete-icon"
                onClick={handleClick}
                className={css.icon}
            />
        </InformationBar>
    );
}
