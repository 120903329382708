import { createTheme } from "@mui/material/styles";
// import all fontsources here
import "@fontsource/dm-sans/100.css";
import "@fontsource/dm-sans/200.css";
import "@fontsource/dm-sans/300.css";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/dm-sans/600.css";
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/800.css";
import "@fontsource/dm-sans/900.css";

import { deepmerge } from "@mui/utils";
import { themototaalTheme } from "./label/themototaalTheme";
import { kozienTheme } from "./label/kozienTheme";
import { type Theme } from "@mui/material/styles/createTheme";
import { SiteLabels } from "@/types/SiteLabel";

const label = import.meta.env.VITE_PUBLIC_LABEL;

let siteTheme = null;

switch (label) {
    case SiteLabels.thermoTotaal:
        siteTheme = themototaalTheme;
        break;
    case SiteLabels.kozien:
        siteTheme = kozienTheme;
        break;
    default:
        throw new Error("Site label not found in context");
}

export const theme = createTheme(
    deepmerge(siteTheme, {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        typography: {
            fontFamily: "DM Sans",
            fontSize: 14,
            htmlFontSize: 16,
            fontWeightLight: 300,
            fontWeightMedium: 700,
            fontWeightRegular: 400,
            letterSpacing: "normal",
            color: siteTheme.palette.anthracite,
            h1: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1.5rem, 5vw, 2rem)",
                fontWeight: 600,
                fontStyle: "normal",
                lineHeight: "1.1",
                marginBottom: ".5rem",
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
            h2: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1.5rem, 5vw, 2rem)",
                fontWeight: 700,
                lineHeight: "1.1",
                marginBottom: ".5rem",
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
            h3: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1.2rem, 5vw, 1.5rem)",
                fontWeight: 600,
                lineHeight: "140%",
                color: siteTheme.palette.anthracite,
            },
            h4: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1.24rem, 5vw, 1.5rem)",
                lineHeight: "134%",
                letterSpacing: "normal",
                fontWeight: 600,
                color: siteTheme.palette.anthracite,
            },
            h5: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1.2rem, 5vw, 1.3rem)",
                fontWeight: 600,
                lineHeight: "140%",
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
            h6: {
                fontFamily: "DM Sans",
                fontSize: "clamp(1rem, 5vw, 1.125rem)",
                fontWeight: 700,
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
            body1: {
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "1.65em",
                textAlign: "left",
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
            body2: {
                fontFamily: "DM Sans",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "1.65em",
                textAlign: "left",
                letterSpacing: "normal",
                color: siteTheme.palette.anthracite,
            },
        },
        components: {
            MuiContainer: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        paddingLeft: theme.spacing(2.5),
                        paddingRight: theme.spacing(2.5),
                        [theme.breakpoints.up("lg")]: {
                            maxWidth: "1436px",
                            paddingLeft: theme.spacing(9),
                            paddingRight: theme.spacing(9),
                        },
                    }),
                },
                defaultProps: {
                    maxWidth: "xl",
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        fontFamily: "DM Sans",
                        borderRadius: "999px",
                        textTransform: "none",
                        fontWeight: 500,
                        boxShadow: "none",
                        fontSize: "1rem",
                        paddingLeft: theme.spacing(2.5),
                        paddingRight: theme.spacing(2.5),
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        lineHeight: "1.5",
                    }),
                    endIcon: {
                        "& > *:nth-of-type(1n + 1)": {
                            fontSize: "16px",
                        },
                    },
                },
                variants: [
                    {
                        props: {
                            variant: "contained",
                            color: "primary",
                        },
                        style: ({ theme }: { theme: Theme }) => ({
                            backgroundColor: theme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.dark,
                                boxShadow: "none",
                            },
                        }),
                    },
                    {
                        props: {
                            size: "small",
                        },
                        style: () => ({
                            fontSize: ".875rem",
                            padding: "4px 12px",
                        }),
                    },
                    {
                        props: {
                            variant: "outlined",
                        },
                        style: () => ({
                            backgroundColor: "transparent",
                        }),
                    },
                    {
                        props: {
                            variant: "outlined",
                            color: "primary",
                        },
                        style: ({ theme }: { theme: Theme }) => ({
                            borderColor: theme.palette.neutral.light,
                            color: theme.palette.anthracite,
                            fill: theme.palette.anthracite,
                        }),
                    },
                    {
                        props: {
                            variant: "text",
                            color: "primary",
                        },
                        style: ({ theme }: { theme: Theme }) => ({
                            color: theme.palette.anthracite,
                            fill: theme.palette.anthracite,
                        }),
                    },
                    {
                        props: {
                            variant: "text",
                        },
                        style: () => ({
                            ":hover": {
                                backgroundColor: "transparent",
                            },
                        }),
                    },
                    {
                        props: {
                            size: "large",
                        },
                        style: ({ theme }: { theme: Theme }) => ({
                            paddingTop: theme.spacing(1.5),
                            paddingBottom: theme.spacing(1.5),
                        }),
                    },
                ],
            },
            MuiIconButton: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        [theme.breakpoints.down("md")]: {
                            boxShadow: `inset 0 0 0 1px ${theme.palette.border}`,
                        },
                    }),
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        ".MuiIconButton-root": {
                            position: "absolute",
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                        },
                    }),
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        fontWeight: 700,
                        textTransform: "uppercase",
                        paddingLeft: theme.spacing(0.5),
                        paddingRight: theme.spacing(0.5),
                        paddingTop: theme.spacing(0.5),
                        paddingBottom: theme.spacing(0.5),
                    }),
                },
                variants: [
                    {
                        props: { variant: "filled" },
                        style: ({ theme }: { theme: Theme }) => ({
                            backgroundColor: theme.palette.neutral.main,
                            color: theme.palette.neutral.contrastText,
                            fill: theme.palette.neutral.contrastText,
                            svg: {
                                path: {
                                    stroke: theme.palette.neutral.contrastText,
                                },
                            },
                        }),
                    },
                ],
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: () => ({
                        fontSize: "1rem",
                    }),
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(5),
                    }),
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        fontSize: ".8rem",
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: ({ theme }: { theme: Theme }) => ({
                        color: theme.palette.anthracite,
                        fontFamily: "DM Sans",
                        marginBottom: theme.spacing(1),
                        fontWeight: 600,
                    }),
                },
            },
        },
    }),
);
``;
