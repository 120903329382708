import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@mui/material/CssBaseline";
import client from "./lib/apollo/client/cms";
import "./styles/general.scss";
import { useRef } from "react";
import { createFeaturesStore, FeaturesContext } from "./store/features";
import { SnackbarProvider } from "notistack";
import { SiteProvider } from "./contexts/Site/Site.context";
import { CustomRouterProvider } from "@/router/CustomRouterProvider";

function App() {
    const featuresStore = useRef(createFeaturesStore()).current;

    return (
        <ApolloProvider client={client}>
            <SiteProvider>
                <CssBaseline />
                <FeaturesContext.Provider value={featuresStore}>
                    <SnackbarProvider>
                        <CustomRouterProvider />
                    </SnackbarProvider>
                </FeaturesContext.Provider>
            </SiteProvider>
        </ApolloProvider>
    );
}

export default App;
