
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "BackdoorPane_VerticalSubPanes": [
      "BreastworkPane",
      "FixedGlassPane"
    ],
    "DoorType_PriceRules": [
      "PriceRule"
    ],
    "GardenDoorPane_VerticalSubPanes": [
      "BreastworkPane",
      "FixedGlassPane"
    ],
    "HorizontalPanes_VerticalPanes": [
      "BackdoorPane",
      "BreastworkPane",
      "DoubleSlidingDoor",
      "FixedGlassPane",
      "FrontDoorPane",
      "GardenDoorPane",
      "MovingWindow",
      "SlidingDoor",
      "TiltAndTurnWindow",
      "TiltWindow"
    ],
    "PayloadPreference_User": [
      "User"
    ],
    "Product_FeatureGroups_Features": [
      "BooleanFeatureInputBlock",
      "BreastworkTypesFeatureInputBlock",
      "ColorsFeatureInputBlock",
      "DoorTypesFeatureInputBlock",
      "DropdownFeatureInputBlock",
      "GlassTypesFeatureInputBlock",
      "HandlesFeatureInputBlock",
      "NumericFeatureInputBlock",
      "PaneArrangementInputBlock",
      "PlasticProfilesFeatureInputBlock",
      "TextFeatureInputBlock",
      "VentilationGrillesFeatureInputBlock"
    ],
    "Service_Features": [
      "ServiceBooleanFeatureInputBlock"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BackdoorPane_VerticalSubPanes": [
      "BreastworkPane",
      "FixedGlassPane"
    ],
    "DoorType_PriceRules": [
      "PriceRule"
    ],
    "GardenDoorPane_VerticalSubPanes": [
      "BreastworkPane",
      "FixedGlassPane"
    ],
    "HorizontalPanes_VerticalPanes": [
      "BackdoorPane",
      "BreastworkPane",
      "DoubleSlidingDoor",
      "FixedGlassPane",
      "FrontDoorPane",
      "GardenDoorPane",
      "MovingWindow",
      "SlidingDoor",
      "TiltAndTurnWindow",
      "TiltWindow"
    ],
    "PayloadPreference_User": [
      "User"
    ],
    "Product_FeatureGroups_Features": [
      "BooleanFeatureInputBlock",
      "BreastworkTypesFeatureInputBlock",
      "ColorsFeatureInputBlock",
      "DoorTypesFeatureInputBlock",
      "DropdownFeatureInputBlock",
      "GlassTypesFeatureInputBlock",
      "HandlesFeatureInputBlock",
      "NumericFeatureInputBlock",
      "PaneArrangementInputBlock",
      "PlasticProfilesFeatureInputBlock",
      "TextFeatureInputBlock",
      "VentilationGrillesFeatureInputBlock"
    ],
    "Service_Features": [
      "ServiceBooleanFeatureInputBlock"
    ]
  }
};
      export default result;
    