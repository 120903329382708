import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import "./i18n.config.ts";
import { theme } from "./styles/theme";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
);

if (import.meta.env.MODE === "development")
    (window as any).$debug = () =>
        Boolean(
            localStorage.getItem("debug") === "true"
                ? localStorage.removeItem("debug")
                : localStorage.setItem("debug", "true"),
        ) || location.reload();
